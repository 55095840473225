import React, { useEffect, useState } from "react"
import ReactPlayer from "react-player"
import { Fancybox as NativeFancybox } from "@fancyapps/ui/dist/fancybox.esm.js"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import "@fancyapps/ui/dist/fancybox.css"
import "../../styles/testimonials-grid.scss"

export default function TestimonialsGrid({ data }) {
  const isBrowser = typeof window !== "undefined"

  useEffect(() => {
    if (isBrowser) {
      const hash = window?.location?.hash?.replace('#', '')

      if (hash) {
        const testimonial = document?.querySelector('div[data-slug="' + hash + '"]')
        const index = [...testimonial?.parentElement?.children].indexOf(testimonial)

        openModal(index)
      }
    }
  }, [])

  function openModal(index) {
    setModalIndex(index)
    return setShowModal(true)
  }

  function closeModal() {
    return setShowModal(false)
  }

  function nextTestimonial(lastIndex) {
    if (modalIndex === lastIndex) {
      return
    }
    return setModalIndex(modalIndex + 1)
  }

  function prevTestimonial() {
    if (modalIndex === 0) {
      return
    }
    return setModalIndex(modalIndex - 1)
  }

  console.log('testimonials grid', data)

  const headerTitle = data?.title
  const headerSubtitle = data?.subtitle

  const [showModal, setShowModal] = useState(false)
  const [modalIndex, setModalIndex] = useState(2)

  const topTestimonials = ( data?.testimonials ) ? data?.testimonials : [];
  let categories = data?.category?.map(category => {
    return category.slug
  })

  categories = (categories) ? categories : ['airplane', 'helicopters']

  console.log('testimonials grid ctas', categories)

  return (
    <StaticQuery
      query={graphql`
        query TestimonialsGridQuery {
          allWpTestimonial {
            nodes {
              categories {
                nodes {
                  name
                  slug
                }
              }
              title
              content
              slug
              featuredImage {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                  publicUrl
                  sourceUrl
                }
              }
              testimonials {
                instagramUrl {
                  target
                  title
                  url
                }
                video
                position
              }
            }
          }
        }
      `}
      render={data => {
        let testimonials = [...topTestimonials, ...data?.allWpTestimonial?.nodes]

        testimonials = testimonials.map(testimonial => {
          const inCategory = testimonial?.categories?.nodes.map(category => {
            return categories.includes(category?.slug)
          })

          if (inCategory.includes(true)) {
            return testimonial
          }
        })

        testimonials = testimonials.filter( Boolean );
        testimonials = [...new Map(testimonials.map((item) => [item['slug'], item])).values()]

        let lastTestimonialIndex = testimonials.length - 1

        console.log('testimonials data', testimonials)

        return (
          <div className="testimonials-grid-wrapper ">
            <h2 className="title">
              {headerSubtitle && (
                <span className="subtitle">{headerSubtitle}</span>
              )}
              {headerTitle}
            </h2>
            <div className="testimonials-grid">
              {testimonials && testimonials?.map((testimonial, index) => {
                return (
                  <div className="testimonial-image-wrapper" data-slug={testimonial?.slug} onClick={() => openModal(index)}>
                    {testimonial?.testimonials?.video && (
                      <svg width="33px" height="38px" viewBox="0 0 33 38" version="1.1"> <g id="Other-Pages" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g id="14---Testimonials-Heli" transform="translate(-891.000000, -1549.000000)" fill="#FFFFFF"> <g id="testimonial-copy" transform="translate(194.000000, 369.000000)"> <g id="testimonials"> <g id="Group-2-Copy-2" transform="translate(0.000000, 1161.000000)"> <g id="FB_IMG_1644880070773-Copy-4" transform="translate(387.000000, 0.000000)"> <path d="M334.518543,26.9959233 L347.06281,46.7879896 C348.836755,49.5868796 348.005872,53.2938943 345.206982,55.0678387 C344.246305,55.6767184 343.132352,56 341.994972,56 L316.906437,56 C313.592728,56 310.906437,53.3137085 310.906437,50 C310.906437,48.8626195 311.229718,47.7486664 311.838598,46.7879896 L324.382866,26.9959233 C326.15681,24.1970332 329.863825,23.3661506 332.662715,25.140095 C333.410699,25.6141694 334.044468,26.2479392 334.518543,26.9959233 Z" id="Triangle" transform="translate(329.450704, 37.500000) scale(-1, 1) rotate(-90.000000) translate(-329.450704, -37.500000) "></path> </g> </g> </g> </g> </g> </g> </svg>
                    )}
                    <GatsbyImage
                      className="testimonial-image"
                      alt={testimonial?.featuredImage?.node?.altText}
                      image={testimonial?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData}
                    />
                  </div>
                )
              })}
            </div>

            <div className={"testimonial-modal-wrapper " + (showModal ? 'open' : '')}>

              <div className="overlay" onClick={() => closeModal()}></div>

              <div className="testimonial-modal">
                <div className="image-wrapper">
                  {testimonials[modalIndex]?.testimonials?.video ? (
                    <ReactPlayer
                    controls={true}
                    playsinline={true}
                    className='react-player'
                    url={testimonials[modalIndex]?.testimonials?.video}
                    width="100%"
                    height="100%"
                    />
                  ) : (
                    <GatsbyImage
                      alt={testimonials[modalIndex]?.featuredImage?.node?.altText}
                      image={testimonials[modalIndex].featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData}
                    />
                  )}
                </div>
                <div className="text-content-wrapper">
                  <span className="close-btn" onClick={() => closeModal()}>x</span>
                  <h2 className="title">{testimonials[modalIndex]?.title}</h2>
                  <h4 className="subtitle">{testimonials[modalIndex]?.subtitle}</h4>
                  <p className="description" dangerouslySetInnerHTML={{ __html: testimonials[modalIndex]?.content}}></p>
                </div>
                <div className={"testimonial-btn-next " + (modalIndex === lastTestimonialIndex ? 'btn-disabled' : '')} onClick={() => nextTestimonial(lastTestimonialIndex)}></div>
                <div className={"testimonial-btn-prev " + (modalIndex === 0 ? 'btn-disabled' : '')} onClick={() => prevTestimonial()}></div>
              </div>

            </div>

          </div>
        )
      }}
    />
  )
}
