import React from "react"
import { Link } from "gatsby"

import "../../styles/grid.scss"
import "../../styles/financing-table.scss"

export default function FinancingTable({ data }) {
  return (
    <div className="financing-table-wrapper">
      <div className="container">
        {data?.title ? (
          <h2 className="title">{data?.title}</h2>
        ) : null}

        {data?.content ? (
          <div className="description" dangerouslySetInnerHTML={{ __html: data?.content}}></div>
        ) : null}

        {data?.image ? (
          <div className="image-wrapper">
            <img src={data?.image?.localFile?.publicURL} alt={data?.image?.altText} loading="lazy" />
          </div>
        ) : null}

        {data?.buttons ? (
          <div className="button-wrapper">
            {data?.buttons?.map(button => {
              console.log(button, 'but')
              return (
                <Link to={button?.button?.url} target={button?.button?.target} className="button outline">{button?.button?.title}</Link>
              )
            })}
          </div>
        ) : null}
      </div>
    </div>
  )
}
