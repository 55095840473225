import React from "react"

import "../../styles/featured-slider.scss"
import {Link} from "gatsby";
import {EffectFade, Navigation} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react"
import {GatsbyImage} from "gatsby-plugin-image";
import ReactPlayer from "react-player"; // include sass styles

export default function FeaturedSlider({ data }) {
  console.log('featured slider ', data)

  return (
    <div className="featured-slider-wrapper">
      <div className={"featured-slider " + (data.slides.length === 1 ? 'one-slide' : '')}>
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          modules={[Navigation, EffectFade]}
          navigation
          effect="fade"
          fadeEffect={{"crossFade": true}}
        >
          {data?.slides && data?.slides?.map(slider => {
            return (
              <SwiperSlide
              >
                <div className="featured-block">
                  <h4 className="subtitle position">{slider?.position}</h4>
                  <h2 className="title">{slider?.title}</h2>
                  <h4 className="subtitle">{slider?.subtitle}</h4>
                  <div className="description" dangerouslySetInnerHTML={{ __html: slider?.content}}></div>
                </div>
                <div className="featured-image">
                  {slider?.video &&
                  <ReactPlayer
                    className='react-player'
                    url={slider?.video}
                    width="100%"
                    height="100%"
                    controls={true}
                    playsinline={true}
                  />
                  }

                  {slider?.image &&
                  <GatsbyImage className="image-container" alt="image" image={slider?.image?.localFile.childImageSharp.gatsbyImageData} />
                  }
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </div>
  )
}
