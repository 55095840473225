import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import "../../styles/text-content.scss" // include sass styles

export default function TextContent({ data }) {
  const dark = (data?.darkTheme) ? 'dark-theme' : ''
  return (
    <div className={`text-content-wrapper ${dark}`} style={{'backgroundColor':data?.backgroundColor}}>
      <div className="text-content">
        {data?.subtitle ? (
          <h4 className="subtitle">{data?.subtitle}</h4>
        ) : null}

        {data?.title ? (
          <h2 className="title">{data?.title}</h2>
        ) : null}

        {data?.content ? (
          <div className="description" dangerouslySetInnerHTML={{__html: data?.content}}></div>
        ) : null}

        {data?.image && (
          <div className="image-wrap">
            {data?.image?.localFile?.publicURL?.includes('.svg') ? (
              <img src={data?.image?.localFile?.publicURL} alt={data?.image?.altText} loading="lazy" />
            ) : (
              <GatsbyImage image={data?.image?.localFile?.childImageSharp?.gatsbyImageData} alt={data?.image?.altText} />
            )}
          </div>
        )}

        {data?.buttons && (
          <div className="button-wrap">
            {data?.buttons?.map(button => {
              return (
                <Link to={button?.button?.url} target={button?.button?.target} className="button centralized">{button.button?.title}</Link>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}
