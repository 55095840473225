import React from "react"

import "../../styles/image-left.scss" // include sass styles

export default function ImageLeft({ data }) {
  console.log('left image data ', data )

  return (
    <div className="image-left-wrapper">
      <div className="image-wrapper">
        <img src={data?.image?.localFile.childImageSharp.gatsbyImageData.images.fallback.src} />
      </div>
      <div className="content-wrapper">
        <div className="content">
          <h2 className="title">{data?.title}</h2>
          <h4 className="subtitle">{data?.subtitle}</h4>
          <p className="description" dangerouslySetInnerHTML={{ __html: data?.content}}></p>
          {(data?.button)
            ?
            <a href={data?.button?.url} className="button">{data?.button?.title}</a>
            :
            ''
          }
        </div>
      </div>
    </div>
  )
}
