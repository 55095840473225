import React from "react"

import "../../styles/two-column-side-image.scss"
import {GatsbyImage} from "gatsby-plugin-image"; // include sass styles

export default function TwoColumnSideImage({ data }) {
  console.log('two column side image', data)

  return (
    <div className="two-column-side-image-wrapper">
      <div className="two-column-side-image">
        <h2 className="title">{data?.title}</h2>
        <div className={"two-column-content " + (data?.imageAlignment === "Left" ? 'image-align-left' : '')}>
          <div className="text-wrapper">
            {data?.content &&
            <p className="description" dangerouslySetInnerHTML={{ __html: data?.content}}></p>
            }
          </div>
          <div className="image-wrapper">
            <GatsbyImage alt={data?.image?.altText} image={data?.image?.localFile?.childImageSharp?.gatsbyImageData} />
          </div>
        </div>
      </div>
    </div>
  )
}
