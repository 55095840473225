import React from "react"

import "../../styles/flag-boxes.scss" // include sass styles

export default function FlagBoxes({ data }) {
  console.log('flag boxes ', data)

  return (
    <div className="flag-boxes-wrapper">
      <div className="flag-boxes">
        <div className="col1">
          {data?.col1?.boxes && data?.col1?.boxes?.map(box => {
            return (
              <div className="box">
                <div className="flag">
                  <h2 className="title white">{box?.title}</h2>
                </div>
                <p className="description" dangerouslySetInnerHTML={{ __html: box?.content}}></p>
              </div>
            )
          })}
        </div>
        <div className="col2">
          {data?.col2?.boxes && data?.col2?.boxes?.map(box => {
            return (
              <div className="box">
                <div className="flag">
                  <h2 className="title white">{box?.title}</h2>
                  </div>
                <p className="description" dangerouslySetInnerHTML={{ __html: box?.content}}></p>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
