import React from "react"

import "../../styles/two-column-with-box.scss" // include sass styles

export default function TwoColumnWithBox({ data }) {
  console.log('two column with box', data)

  return (
    <div className="two-column-with-box-wrapper">
      <div className="two-column-with-box">
        <div className="content-col">
          <h2 className="title">{data?.title}</h2>
          <p className="description" dangerouslySetInnerHTML={{ __html: data?.content}}></p>
        </div>
        <div className="box-col">
          <div className="box description" dangerouslySetInnerHTML={{ __html: data?.box?.content}}></div>
        </div>
      </div>
    </div>
  )
}
