import React, { useState, useEffect, Fragment } from "react"
import { useStaticQuery, graphql, Link, navigate } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"
import { globalHistory } from "@reach/router"
import Form from "../../components/form"

import "../../styles/grid.scss"
import "../../styles/journey.scss"

export default function Journey({ data }) {
  console.log('journey', data)

  const [showSections, setShowSections] = useState(true)
  const [showOptions, setShowOptions] = useState(true)
  const [showSubOptions, setShowSubOptions] = useState(false)
  const [showEndOptions, setShowEndOptions] = useState(false)

  const [section, setSection] = useState('')
  const [options, setOptions] = useState('')
  const [subOptions, setSubOptions] = useState('')
  const [endOptions, setEndOptions] = useState('')
  const [title, setTitle] = useState(data?.title)
  const [subtitle, setSubtitle] = useState(data?.subtitle)
  const [selectedOption, setSelectedOption] = useState(false)
  const [selectedSubOption, setSelectedSubOption] = useState(false)
  const [selectedEndOption, setSelectedEndOption] = useState(false)

  const path = `${section?.title} / ${selectedOption?.title} / ${selectedSubOption?.title} / ${selectedEndOption?.title}`

  const form = useStaticQuery(graphql`
    query journeyFormQuery {
      wpGfForm(databaseId: { eq: 2 }) {
        ...GravityFormFields
      }
    }
  `);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)
    console.log('reload?')

    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        resetState()
      }
    })
  }, [])

  function resetState() {
    setShowSections(true)
    setShowOptions(true)
    setShowSubOptions(false)
    setShowEndOptions(false)
    setSection('')
    setOptions('')
    setSubOptions('')
    setEndOptions('')
    setTitle(data?.title)
    setSubtitle(data?.subtitle)
    setSelectedOption(false)
    setSelectedSubOption(false)
    setSelectedEndOption(false)
  }

  function escFunction() {
    document.querySelectorAll('.modal').forEach(el => {
      el.classList.add('hidden')
    })
  }

  function toggleModal(e) {
    e.stopPropagation();
    const modal = e.currentTarget?.nextSibling

    if (modal?.classList.contains('hidden')) {
      modal.classList.remove('hidden')
    } else {
      escFunction()
    }
  }

  function selectSection(e, section) {
    setSection(section)
    setSubtitle(data?.optionsTitle)
    setShowSections(false)
    setOptions(section?.options)
  }

  function selectOption(e, section) {
    if (!section?.redirect) {
      setSelectedOption(section)
      setSubOptions(section?.subOptions)
      setSubtitle('')
      setTitle('Journey')
    } else {
      navigate(section.redirect?.url)
    }
  }

  function changeOption(e, section) {
    e.stopPropagation();

    setSubOptions(false)
    setEndOptions(false)
    setSelectedSubOption(false)
    setSelectedEndOption(false)
    setSelectedOption(section)
  }

  function selectSubOption(e, section) {
    if (!section?.redirect) {
      setSelectedSubOption(section)
      setEndOptions(section?.endOptions)
    } else {
      navigate(section.redirect?.url)
    }
  }

  function changeSubOption(e, section) {
    e.stopPropagation();

    setEndOptions(false)
    setSelectedEndOption(false)
    setSelectedSubOption(section)
  }

  function selectEndOption(e, section) {
    if (!section?.redirect) {
      setSelectedEndOption(section)
    } else {
      navigate(section.redirect?.url)
    }
  }

  function changeEndOption(e, section) {
    e.stopPropagation();

    setSelectedEndOption(false)
  }

  return (
    <div className="journey-wrapper">
      <div className="container">
        {section?.title && (
          <div className="section-subtitle">{section?.title}</div>
        )}

        <h1 className="title">{title}</h1>

        {subtitle && (
          <div className="subtitle">{subtitle}</div>
        )}

        {showSections && (
          <div className="row sections">
            {data?.sections?.map(section => {
              return (
                <div className="section-select col" onClick={(e) => selectSection(e, section)}>
                  <div className="row">
                    <div className="col featured-image">
                      <GatsbyImage image={section?.image?.localFile?.childImageSharp?.gatsbyImageData} alt={section?.image?.altText} />
                    </div>
                    <div className="col section-title">
                      <h2>{section?.title}</h2>
                      {section?.content ? (
                        <Fragment>
                          <div className="info" onClick={toggleModal}>i</div>
                          <div className="modal hidden">
                            <div className="modal-content">
                              <button className="close" onClick={toggleModal}></button>
                              <div className="row">
                                <div className="col">
                                  <h2>{section?.title}</h2>
                                  <div className="description" dangerouslySetInnerHTML={{__html: section?.content}}></div>
                                  <Link to={section?.button?.url} target={section?.button?.target} className="button">{section?.button?.title}</Link>
                                </div>
                                <div className="col">
                                  <GatsbyImage image={section?.image?.localFile?.childImageSharp?.gatsbyImageData} alt={section?.image?.altText} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Fragment>
                      ) : null}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )}

        {options && (
          <Fragment>
            <div className="back" onClick={resetState}>{`< Back`}</div>
            <div className={`row sections ${selectedOption && subOptions ? 'collapsed' : ''}`}>
              {options?.map(section => {
                const selected = (subOptions && section?.title === selectedOption?.title) ? 'selected' : ''

                return (
                  <div className={`section-select col ${selected}`} onClick={(e) => selectOption(e, section)}>
                    <div className="row">
                      <div className="col featured-image">
                        <GatsbyImage image={section?.image?.localFile?.childImageSharp?.gatsbyImageData} alt={section?.image?.altText} />
                      </div>
                      <div className="col section-title">
                        <h2>{section?.title}</h2>

                        {selected && (
                          <div className="change" onClick={(e) => changeOption(e, section)}>Change ></div>
                        )}

                        {section?.content && !selected ? (
                          <Fragment>
                            <div className="info" onClick={toggleModal}>i</div>
                            <div className="modal hidden">
                              <div className="modal-content">
                                <button className="close" onClick={toggleModal}></button>
                                <div className="row">
                                  <div className="col">
                                    <h2>{section?.title}</h2>
                                    <div className="description" dangerouslySetInnerHTML={{__html: section?.content}}></div>
                                    <Link to={section?.button?.url} target={section?.button?.target} className="button">{section?.button?.title}</Link>
                                  </div>
                                  <div className="col">
                                    <GatsbyImage image={section?.image?.localFile?.childImageSharp?.gatsbyImageData} alt={section?.image?.altText} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        ) : null}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </Fragment>
        )}

        {subOptions && (
          <Fragment>
            {!selectedSubOption && (
              <div className="options-title">{data?.optionsTitle}</div>
            )}

            <div className={`row sections options ${selectedSubOption && endOptions ? 'collapsed' : ''}`}>
              {subOptions?.map(section => {
                const selected = (endOptions && section?.title === selectedSubOption?.title) ? 'selected' : ''

                return (
                  <div className={`section-select col ${selected}`} onClick={(e) => selectSubOption(e, section)}>
                    <div className="row">
                      <div className="col featured-image">
                        <GatsbyImage image={section?.image?.localFile?.childImageSharp?.gatsbyImageData} alt={section?.image?.altText} />
                      </div>
                      <div className="col section-title">
                        <h2>{section?.title}</h2>

                        {selected && (
                          <div className="change" onClick={(e) => changeSubOption(e, section)}>Change ></div>
                        )}

                        {section?.content && !selected ? (
                          <Fragment>
                            <div className="info" onClick={toggleModal}>i</div>
                            <div className="modal hidden">
                              <div className="modal-content">
                                <button className="close" onClick={toggleModal}></button>
                                <div className="row">
                                  <div className="col">
                                    <h2>{section?.title}</h2>
                                    <div className="description" dangerouslySetInnerHTML={{__html: section?.content}}></div>
                                    <Link to={section?.button?.url} target={section?.button?.target} className="button">{section?.button?.title}</Link>
                                  </div>
                                  <div className="col">
                                    <GatsbyImage image={section?.image?.localFile?.childImageSharp?.gatsbyImageData} alt={section?.image?.altText} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        ) : null}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </Fragment>
        )}

        {endOptions && (
          <Fragment>
            {!selectedEndOption && (
              <div className="options-title">{data?.optionsTitle}</div>
            )}

            <div className={`row sections options ${selectedEndOption ? 'collapsed' : ''}`}>
              {endOptions?.map(section => {
                const selected = (endOptions && section?.title === selectedEndOption?.title) ? 'selected' : ''

                return (
                  <div className={`section-select col ${selected}`} onClick={(e) => selectEndOption(e, section)}>
                    <div className="row">
                      <div className="col featured-image">
                        <GatsbyImage image={section?.image?.localFile?.childImageSharp?.gatsbyImageData} alt={section?.image?.altText} />
                      </div>
                      <div className="col section-title">
                        <h2>{section?.title}</h2>

                        {selected && (
                          <div className="change" onClick={(e) => changeEndOption(e, section)}>Change ></div>
                        )}

                        {section?.content && !selected ? (
                          <Fragment>
                            <div className="info" onClick={toggleModal}>i</div>
                            <div className="modal hidden">
                              <div className="modal-content">
                                <button className="close" onClick={toggleModal}></button>
                                <div className="row">
                                  <div className="col">
                                    <h2>{section?.title}</h2>
                                    <div className="description" dangerouslySetInnerHTML={{__html: section?.content}}></div>
                                    <Link to={section?.button?.url} target={section?.button?.target} className="button">{section?.button?.title}</Link>
                                  </div>
                                  <div className="col">
                                    <GatsbyImage image={section?.image?.localFile?.childImageSharp?.gatsbyImageData} alt={section?.image?.altText} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        ) : null}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </Fragment>
        )}
      </div>

      {selectedEndOption ? (
        <div className="results">
          <div className="container">
            <div className="ty-message">
              <h3 className="title">{selectedEndOption?.thankYou?.title}</h3>
              <div className="description" dangerouslySetInnerHTML={{__html: selectedEndOption?.thankYou?.content}}></div>
            </div>
          </div>
          <div className="cta-wrapper">
            <div className="container">
              <div className="row">
                <div className="col form-wrapper">
                  <img src={data?.form?.icon?.localFile?.publicURL} alt={data?.form?.icon?.altText} loading="lazy" />
                  <h4>{data?.form?.title}</h4>
                  <div className="description" dangerouslySetInnerHTML={{__html: selectedEndOption?.formContent}}></div>
                  <Form data={form} presetValues={{ input_1: path }} />
                </div>
                <div className="col learn-more-wrapper">
                  <img src={selectedEndOption?.learnMore?.icon?.localFile?.publicURL} alt={selectedEndOption?.learnMore?.icon?.altText} loading="lazy" />
                  <h4>{selectedEndOption?.learnMore?.title}</h4>
                  <div className="description" dangerouslySetInnerHTML={{__html: selectedEndOption?.learnMore?.content}}></div>
                  <div className="buttons-wrapper">
                    {selectedEndOption?.learnMore?.buttons?.map(button => {
                      return (
                        <Link to={button?.button?.url} target="_blank" className="button">{button?.button?.title}</Link>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}
