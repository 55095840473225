import React from "react"

import "../../styles/time-comparison.scss"
import {GatsbyImage} from "gatsby-plugin-image";
import {graphql, StaticQuery} from "gatsby"; // include sass styles

export default function TimeComparison({ data }) {
  console.log('time comparison ', data)


    return (
      <div className="time-comparison-wrapper">
        <div className="time-comparison">
          <h4 className="subtitle">{data?.subtitle}</h4>
          <h2 className="title" dangerouslySetInnerHTML={{ __html: data?.title}}></h2>
          <table>
            <tr>
              <th></th>
              <th style={{position: "relative"}}>
                <img src={data?.planeIcon?.localFile?.publicURL} alt={data?.carIcon?.altText} />
                <span className="asterisks">**</span>
              </th>
              <th>
                <img src={data?.carIcon?.localFile?.publicURL} alt={data?.carIcon?.altText} />
              </th>
            </tr>
            {data?.rows && data?.rows?.map(row => {
              return (
                <tr>
                  <td>{row?.from} <span className="arrow-right">&#x2192;</span> {row?.to}</td>
                  <td>
                    <div className="time-cell">
                      <span className="time">{row?.time1}</span><br/>
                      <span className="type">{row?.type1}</span>
                    </div>
                  </td>
                  <td>
                    <div className="time-cell">
                      <span className="time">{row?.time2}</span><br/>
                      <span className="type">{row?.type2}</span>
                    </div>
                  </td>
                </tr>
              )
            })}
          </table>
          <p className="description" dangerouslySetInnerHTML={{ __html: data?.content}}></p>
        </div>
      </div>
    )
}
