import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import "../../styles/fleet.scss"
import ReactPlayer from "react-player";

export default function Fleet({ data }) {
  console.log('fle', data)
  return (
    <div className="fleet-wrapper">
      <h2>
        <span>{data?.subtitle}</span>
        {data?.title}
      </h2>
      {data?.video &&
      <ReactPlayer
        className='react-player'
        loop={true}
        volume="0"
        muted={true}
        playing={true}
        playsinline={true}
        url={data?.video}
        width="100%"
        height="1000px"
      />
      }
      {!data?.video &&
      <GatsbyImage image={data?.background?.localFile?.childImageSharp?.gatsbyImageData} alt={data?.background?.altText} />
      }

      <div className="box">
        <h3 className="title">{data?.box?.title}</h3>
        <div className="description" dangerouslySetInnerHTML={{__html: data?.box?.content}}></div>
        <Link to={data?.box?.button?.url} target={data?.box?.button?.target} className="button">{data?.box?.button?.title}</Link>
      </div>
    </div>
  )
}
