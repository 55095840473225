import React, {useEffect, useState} from "react"
import ReactPlayer from "react-player";
import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { GatsbyImage } from "gatsby-plugin-image"

import "swiper/css";
import "swiper/css/pagination";
import "../../styles/content-with-sidebar.scss"

const isBrowser = typeof window !== "undefined"

export default function ContentWithSidebar({ data }) {

  const [width, setWidth] = useState((isBrowser) ? window.innerWidth : 0);

  function handleWindowSizeChange() {
    setWidth((isBrowser) ? window.innerWidth : 0);
  }
  useEffect(() => {
    if (isBrowser) {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
      }
    }
  }, []);

  const smallWidth = width <= 420;

  return (
    <div className="content-with-sidebar-wrapper">
      <div className="sidebar">
        <div className="text-content bordered">
          <h2 className="title">{data?.sidebar?.textContent.title}</h2>
          <p className="description" dangerouslySetInnerHTML={{ __html: data?.sidebar?.textContent?.content}}></p>
          <a href={data?.sidebar?.textContent?.button.url} className="button">{data?.sidebar?.textContent?.button?.title}</a>
        </div>
        <div className="video-wrapper">
          <ReactPlayer
            width="100%"
            height={smallWidth ? "270px" : "380px"}
            controls={true}
            playsinline={true}
            url={data?.sidebar?.videoContent?.video}
          />
        </div>
        <div className="text-content desktop">
          <h2 className="title">{data?.sidebar?.videoContent?.title}</h2>
          <p className="description" dangerouslySetInnerHTML={{ __html: data?.sidebar?.videoContent?.content }}></p>
        </div>
      </div>
      <div className="main">
        <div className="image-slider">
          <Swiper
            modules={[Autoplay, Pagination]}
            autoplay={{delay: 5000}}
            pagination={{ clickable: true }}
            spaceBetween={0}
            slidesPerView={1}
          >
            {data?.main?.slider?.map(image => (
              <SwiperSlide
              >
                <GatsbyImage image={image?.localFile.childImageSharp.gatsbyImageData} alt={image?.altText} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="text-content">
          <h2 className="title">{data?.main?.title}</h2>
          <p className="description" dangerouslySetInnerHTML={{ __html: data?.main?.content}}></p>
          <a href={data?.main?.button.url} className="button">{data?.main?.button.title}</a>
        </div>
        <div className="gallery">
          {data?.main?.gallery?.slice(0, 3).map(image => {
            return (
              <img src={image?.localFile.childImageSharp.gatsbyImageData.images.fallback.src} alt="image" />
            )
          })}
        </div>
        <div className="text-content mobile">
          <h2 className="title">{data?.sidebar?.videoContent?.title}</h2>
          <p className="description" dangerouslySetInnerHTML={{ __html: data?.sidebar?.videoContent?.content }}></p>
        </div>
      </div>
    </div>
  )
}
